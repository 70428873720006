.duplicate {
  padding: 16px;
  background-color: #8080800a;
  width: 30vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0,0,0,0.13);
  &__title {
    margin: 0 0 0 5px;
    color: gray;
    text-transform: uppercase;
    font-size: 18px;
  }

  &__inner {
    padding: 5px;
  }
  ion-input{
    border: 1px solid rgba(0,0,0,0.13); margin: 5px 10px; background-color: white;
  }
}
