.day-slot {
    --min-height: inherit;

    :hover {
        cursor: pointer;
        opacity: .8;
    }

    .day-slot-label {
        margin: 2px;
        font-size: .9em;
    }

    ion-chip {
        height: 22px;
        margin: 0;
        margin-left: 10px;
        font-weight: bolder;
        font-size: .9em;
        color: white;
        text-shadow: 0 0 3px rgba(0,0,0,1);
    }
}