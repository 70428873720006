.week-container {
  @media screen and (min-width: 800px) {
    display: flex;
    flex-wrap: nowrap;

    .day-container {
      flex: 1;

      .list-md {
        padding-top: 0;
      }
    }
  }

  .day-label {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 1.2em;
  }

}

.custom-skeleton ion-skeleton-text {
  line-height: 13px;
}

.custom-skeleton ion-skeleton-text:last-child {
  margin-bottom: 5px;
}