/* @import "../../theme/variables.css"; */
.form-container {
  margin: 32px auto;
  border: 1px solid rgba(0,0,0,0.13);
  padding: 16px; 
  min-height: 80vh;
  form, ion-list { overflow: visible; height: 100%;}
  ion-button { margin-top: 32px; }
  .label-stacked {
    width: 128px;
  }
  ion-row, ion-item, .label-stacked  {    
    margin-bottom: 8px;
  }
  ion-textarea { padding: 8px;
  }
}

.feedback {
  color: var(--ion-color-danger);
  padding-left: 1em;
  font-size: 0.8em;
  padding-top: 0.3em;
}

.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(min-width: 800px) {
  .form-container {
    width: 40%;
  }
}

ion-modal {
  .form-container {
    width: 100%;
  }
}