.navigation-controls {
  position: sticky;
  top: 0;
  z-index: 9999;
  background: white;
  display: flex;
  justify-content: center;
}

.planning-outer-container {
  overflow: auto;
  height: 100%;
}