.date-selector-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 10px;

  p {
    text-align: center;
    font-family: "Fs Dillon", Sans-serif;
    color: black;
    font-weight: 500;
    font-size: 1em;
    margin: 0 5px;
    flex: 1;

    &.date {
      flex: 4;
      cursor: pointer;
    }

    i {
      cursor: pointer;
      padding: 5px;
    }
  }
}

ion-popover.calendar {
  --background: none;
  --box-shadow: none;
}
